import React from "react";

import { HighlightContainer, FirstStar, SecondStar } from "./styles";

import star from "assets/img/star.svg";

interface IProps {
    children: React.ReactNode;
    isHighlighted?: boolean;
}

const HighlightedIcon = ({ children, isHighlighted = false }: IProps) => (
    <HighlightContainer isHighlighted={isHighlighted}>
        {isHighlighted && <FirstStar src={star} />}
        {children}
        {isHighlighted && <SecondStar src={star} />}
    </HighlightContainer>
);

export default HighlightedIcon;
