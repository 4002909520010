import React from "react";

// Dependencies
import ProdigioTheme from "prodigio-themes";

// Assets
import logo from "static/promedicina/logo.svg";
import logoSmall from "static/promedicina/logoSmall.svg";
import logoWhite from "static/promedicina/logoWhite.svg";

// Social Icons
import FacebookIcon from "prodigio-icons/web/outlined/Facebook";
import IntsgramIcon from "prodigio-icons/web/outlined/Instagram";
import TwitterIcon from "prodigio-icons/web/outlined/Twitter";
import YoutubeIcon from "prodigio-icons/web/outlined/Youtube";

// Navigation Icons
import EssayIcon from "prodigio-icons/web/outlined/Essay";
import SimuladoIcon from "prodigio-icons/web/outlined/Simulado";
import CustomExerciseListIcon from "prodigio-icons/web/outlined/CustomExerciseList";

import ProjectIcon from "prodigio-icons/web/outlined/Project";
import PreviousExamsIcon from "prodigio-icons/web/outlined/ProvasAnteriores";
import StudyPlanIcon from "prodigio-icons/web/outlined/StudyPlan";
import SubjectIcon from "prodigio-icons/web/outlined/Subject";
import FileIcon from "prodigio-icons/web/outlined/Apoio";
import QRCode from "prodigio-icons/web/outlined/QrCode";
import CalendarIcon from "prodigio-icons/web/outlined/Calendar";

// Apps Icons
import AppStoreIcon from "prodigio-icons/web/outlined/AppStore";
import GooglePlayIcon from "prodigio-icons/web/outlined/GooglePlay";

// Components
import HighlightedIcon from "components/HighlightedIcon";

// Helpers
import { INavigation } from "interfaces/INavigation";
import { generateThemeData } from "helpers/generate-theme-data";

const prodigioTheme = new ProdigioTheme({
    project: {
        appUrl: "app.promedicina.com.br",
        brand: "promedicina",
        name: "promedicina",
        slug: "promedicina",
        url: "app.promedicina.com.br",
        logo,
        logoSmall,
        logoWhite
    },
    mainColors: {
        base: "#83899E",
        brand: "#11828F",
        secondary: "#D64309"
    }
});

export const theme = generateThemeData(prodigioTheme, {
    brand: {
        100: "#7AE8E1",
        200: "#1FBCCC",
        300: "#11828F",
        400: "#0F697C",
        500: "#004D65"
    },
    secondary: {
        100: "#FEB273",
        200: "#FF782F",
        300: "#D64309",
        400: "#BF320A",
        500: "#9C2A10"
    }
});

export const menuColors: any = {};

export const navigation: INavigation[] = [
    {
        name: "Plano de estudos",
        url: "/app/meu-plano-de-estudos",
        icon: (
            <HighlightedIcon isHighlighted>
                <StudyPlanIcon width="20" height="20" />
            </HighlightedIcon>
        )
    },
    {
        name: "Matérias",
        url: "/app/materias",
        icon: (
            <HighlightedIcon>
                <SubjectIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Agenda de aulas",
        url: "/app/plano-de-estudos",
        icon: (
            <HighlightedIcon>
                <CalendarIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Redações",
        url: "/app/redacoes",
        icon: (
            <HighlightedIcon>
                <EssayIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Simulados",
        url: "/app/simulados",
        icon: (
            <HighlightedIcon>
                <SimuladoIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Banco de questões",
        url: "/app/banco-de-questoes",
        icon: (
            <HighlightedIcon>
                <CustomExerciseListIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Projetos",
        url: "/app/projetos",
        icon: (
            <HighlightedIcon>
                <ProjectIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Provas anteriores",
        url: "/app/provas-anteriores",
        icon: (
            <HighlightedIcon>
                <PreviousExamsIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "Comunicados",
        url: "/app/arquivos",
        icon: (
            <HighlightedIcon>
                <FileIcon width="22" height="22" />
            </HighlightedIcon>
        )
    },
    {
        name: "QRCode",
        url: "/app/qr-code",
        icon: (
            <HighlightedIcon>
                <QRCode width="22" height="22" />
            </HighlightedIcon>
        )
    }
];

export const socialNetworks = [
    {
        name: "Facebook",
        url: "https://www.facebook.com/promedicina",
        icon: <FacebookIcon width="16" height="16" />
    },
    {
        name: "Twitter",
        url: "https://twitter.com/promedicinaoficial",
        icon: <TwitterIcon width="16" height="19" />
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/promedicina",
        icon: <IntsgramIcon width="16" height="16" />
    },
    {
        name: "Youtube",
        url: "https://www.youtube.com/user/ProMedicinaOficial",
        icon: <YoutubeIcon width="23" height="16" />
    }
];

export const mobileApps = [
    {
        name: "Google Play",
        url: "/app/",
        icon: <GooglePlayIcon height="16" width="15" />
    },
    {
        name: "App Store",
        url: "/app/",
        icon: <AppStoreIcon height="16" width="16" />
    }
];
