import styled from "styled-components/macro";
import { theme } from "config/theme";

interface HighlightContainerProps {
    isHighlighted: boolean;
}

export const HighlightContainer = styled.div<HighlightContainerProps>`
    padding: 8px;

    background-color: ${({ isHighlighted }) => (isHighlighted ? theme.colors.secondary[100] : "transparent")};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
`;

export const FirstStar = styled.img`
    position: absolute;
    right: 28px;
    top: 3px;
`;

export const SecondStar = styled.img`
    position: absolute;
    bottom: 3px;
    left: 28px;
`;
